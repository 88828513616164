.login-container {
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.login-container > * {
    margin-right: 5px;
}
.login-container > *:last-child {
    margin-right: 0px;
}

.login-container-name {
    height: 50px;
}
.login-container-btn {
    color: var(--NAV_ITEM_COLOR_FG);
    height: 100%;
    display: inline-flex;
    align-items: center;
}

.login-container-btn:hover {
    color: var(--NAV_ITEM_COLOR_FG_HOVER);
}

.login-container-img {
    height: 50px;
    width: 50px;
    border-radius: 25px;
}
