@import "./../../assets/styles/variables";

.dashboard {
    display: flex;
    flex: 1;
    padding: 1em;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    gap: 1em;
    flex-direction: row;
    font-size: 16px;
    text-align: center;

    .ds-width-1 {
        flex: 1;
        min-width: $ds-percentage * 1;
    }
    .ds-width-2 {
        flex: 2;
        min-width: $ds-percentage * 2;
    }
    .ds-width-3 {
        flex: 3;
        min-width: $ds-percentage * 3;
    }
    .ds-width-4 {
        flex: 4;
        min-width: $ds-percentage * 4;
    }
    .ds-width-5 {
        flex: 5;
        min-width: $ds-percentage * 5;
    }
    .ds-width-6 {
        flex: 6;
        min-width: $ds-percentage * 6;
    }
    .ds-width-7 {
        flex: 7;
        min-width: $ds-percentage * 7;
    }
    .ds-width-8 {
        flex: 8;
        min-width: $ds-percentage * 8;
    }
    .ds-width-9 {
        flex: 9;
        min-width: $ds-percentage * 9;
    }
    .ds-width-10 {
        flex: 10;
        min-width: $ds-percentage * 10;
    }

    .dashboard__container {
        display: flex;
        flex-direction: column;
        height: 18em;
        background-color: $color-white;
        border-radius: 4px 4px 0 0;
        box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.1);
    }

    .container__header {
        min-height: 3em;
        background-color: $color-blue;
        color: white;
        border-radius: 4px 4px 0 0;
        padding-top: 1em;
        font-weight: 600;
    }
}
