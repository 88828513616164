@import "./../../../assets/styles/variables";

.button {
    border: 0;
    font-weight: 600;
    line-height: 1;
    margin-top: 1.5rem;

    &.primary {
        background-color: $color-blue;
        color: $color-white;
    }

    &.secondary {
        background-color: $color-blue;
        color: $color-white;
    }

    &.danger {
        background-color: $color-red;
        color: $color-white;
    }
}
