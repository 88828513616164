.filter-main__container {
    background-color: $color-white;
    padding: 1em 2em;
    flex: 1;
    overflow: auto;
    display: flex;
    flex-direction: column;

    .container__filter-input {
        display: flex;
        flex: 0 0 2em;

        input {
            background-color: $color-white;
            border: 1px $color-light solid;
            border-radius: 4px;
            padding: 0.8em;
            width: 40%;
            background-image: url(./../../../images/icon_search.svg);
            background-repeat: no-repeat;
            background-position: 99% 50%;
            vertical-align: middle;
            @include s {
                width: 100%;
            }
        }
    }

    .container__filter-selected {
        display: flex;
        flex: 0 0 1em;
        justify-content: flex-start;
        flex-direction: row;
        padding: 1em 0em 0.5em 0em;
        gap: 1em;
    }

    .filter {
        display: flex;
        justify-content: space-between;
        font-size: rem(14px);
        padding: 0.2em 2em;
        border-radius: 1em;
        background-color: $color-blue;
        color: $color-white;
    }

    .filter__title {
        flex: 1;
        padding-left: 0em;
        padding-right: 1em;
    }

    .filter__icon {
        height: 100%;
        flex: 0 0 20px;
        background-image: url(./../../../images/icon_cancel.svg);
        background-repeat: no-repeat;
        background-position: 100% 50%;
        vertical-align: middle;
        cursor: pointer;
    }

    /* width */
    ::-webkit-scrollbar {
        width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #888;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}
