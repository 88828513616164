@import "./../../../assets/styles/variables";
@import "./../../../assets/styles/mixins";

.offerlist--phase {
    flex-wrap: wrap;
    margin-bottom: 4em;
    display: flex;
    gap: 0.7em;
    flex-direction: column;

    .phase--title {
        text-align: left;
        display: flex;
        padding: 5px;
        justify-content: space-between;
        background-color: $color-gray-middle;
    }

    .title--input {
        width: 20vw;
        border: 0;
        outline: 0;
        background: transparent;
        padding-bottom: 3px;
        border-bottom: 1px solid white;
        font-size: 16px;
        font-weight: 400;
    }

    .phase--title-filler {
        flex: 1;
    }

    .phase_move-icon {
        display: none;
        flex: 0 0 3em;
        text-align: center;
    }
    .phase_move-icon.visible {
        display: block;
    }

    .phase--task-list {
        padding: 0px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 0.5em;
    }

    .phase__add-task {
        text-align: left;
        position: relative;
        width: 20em;
    }

    .add-task__button {
        background-color: $color-red;
        color: $color-white;
        margin-top: 0.5em;
        padding: 0.5em;
        width: 20rem;
    }

    .phase--add-phase__submenu {
        list-style: none;
        background-color: $color-white;
        border-bottom: none;
        width: 100%;
        margin: 0;
        padding: 0;
        z-index: 1;
        > * {
            padding: 0.7em;
            font-size: rem(14px);
            cursor: pointer;
            border-bottom: 1px solid black;
        }

        > *:last-child {
            border-bottom: none;
        }
        > *:hover {
            background-color: darken($color-gray-middle, 20%);
        }
    }

    .phase__add-task:active,
    .phase__add-task:focus-within {
        .phase--add-phase__submenu {
            display: block;
            position: absolute;
        }
    }

    .title__sum {
        color: black;
        text-align: right;
        margin: 1em 0em;
        flex: 0 280px;
        font-weight: 500;
        font-size: 16px;
    }

    .title__sum-time {
        color: black;
        text-align: right;
        margin: 1em 0em;
        flex: 1;
        font-weight: 500;
        font-size: 16px;
    }

    .phase--footer {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
}

.offerlist--phase-minimized {
    .phase--add-phase-button,
    .phase--task-list-header {
        visibility: collapse;
    }
}
