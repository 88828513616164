html {
  height: 100%;
  width: 100%;
  font-family: $font-family;
  font-weight: 300;
  font-size: 18px;
  overflow-y: hidden;

  @include m {
    font-size: $base-font-size * 0.8;
  }

  @include s {
    font-size: $base-font-size * 0.7;
  }
}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  scroll-behavior: smooth;
}
