@import "./../../assets/styles/variables";

.timesafe--container {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: scroll;
    padding: 20px;

    #TimesafeTableBox {
        margin-top: 10px;
        font-size: 12px;
        font-weight: lighter;
        font-family: Verdana, Geneva, Tahoma, sans-serif;
    }

    #TimesafeBlock {
        margin-top: 10px;
        font-size: 15px;
        font-family: Verdana, Geneva, Tahoma, sans-serif;
        border-color: black;
        border-width: 1px;
        border-style: dashed;

        .select {
            width: 400px;
        }
    }

    #TimesafeLabelBlock {
        width: 400px;
        margin-top: 30px;
        font-size: 15px;
        font-family: Verdana, Geneva, Tahoma, sans-serif;
        border-color: red;
        border-width: 1px;
        border-style: dashed;
    }
    #StartButton {
        margin-top: 60px;
        width: 300px;
        height: 60px;
        background-color: lightGreen;
        font-size: 30px;
        font-weight: bold;
    }
}



.tableContainter > {
    border: 1px;
    border-style: dashed;
    border-color: black;
}
