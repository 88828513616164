@import "./../../../assets/styles/variables";

.quote-edit-document-header__container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    font-weight: 400;
    gap: 10em;
    padding-bottom: 4em;

    .container__left {
        display: flex;
        flex-direction: column;
        flex: 0 0 30%;
        gap: 1em;
        height: 8em;
    }

    .container__customer {
        display: flex;
        flex-direction: column;
        padding: 1em;
        text-align: left;
        background: $color-light;
        height: 8em;
    }

    .container__type {
        display: flex;

        > * {
            text-align: left;
            flex: 1;
        }
    }

    .container__details {
        flex: 0 0 40%;
        display: flex;
        flex-direction: column;
        gap: 0.5em;
        background: $color-white;
    }

    .details__row {
        display: flex;
        justify-content: space-between;
    }

    .row__title {
        flex: 1;
        text-align: left;
    }

    .row__value {
        background: $color-gray-middle;
        flex: 1.5;
        font-size: 16px;
    }

    .disabled {
        background-color: $color-white;
    }
}
