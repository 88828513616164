@import "./../../../assets/styles/variables";

.context-menu {
    position: relative;
}

.context-menu--btn {
    > button {
        background-color: unset;
        width: unset;
        padding: 0;
        margin: 0;
    }
}

.context-menu--content {
    display: none;
    background-color: $color-white;
    width: fit-content;
    margin: 0;
    padding: 0;
    z-index: 1;
    overflow: visible;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.3);
}

.context-menu--btn:active,
.context-menu--btn:focus-within,
.context-menu--btn:focus {
    .context-menu--content {
        display: block;
        position: absolute;
    }
}
