.filter-main__navigation {
    flex: 0 0 10%;
    background-color: $color-white;
    border-right: $color-light 1px solid;
    padding: 1em;
    display: flex;
    flex-direction: column;
    min-width: fit-content;

    .navigation__add-Quote-btn {
        background-color: $color-blue;
        padding: 0em 2em;
        border-radius: 4px;
        height: 2em;
        line-height: 2em;
        display: block;
        justify-content: center;
        cursor: pointer;
        align-items: center;
        color: $color-white;
        transition: $transition-mode;
    }

    .navigation__add-Quote-btn:hover {
        transform: scale(1.02);
    }

    .navigation__group {
        display: flex;
        flex-direction: column;
        margin-top: 1em;
        font-size: rem(16px);
        font-weight: 700;
    }

    .group__title {
        flex: 1;
        text-align: left;
    }
    .group__filters {
        flex: 0 0 100%;
        text-align: left;
    }

    a {
        text-decoration: none;
        display: block;
    }

    .filter,
    a {
        color: darken($color-light, 70%);
        font-weight: 400;
        border-bottom: $color-light 1px solid;
        padding: 0.7em 0em;
        padding-left: 1em;
        background-image: url(./../../../images/icon_add.svg);
        background-repeat: no-repeat;
        background-position: 97% 50%;
        vertical-align: middle;
        cursor: pointer;
        transition: $transition-mode;
        font-size: rem(14px);
    }

    .filter.group,
    a {
        background-image: url(./../../../images/icon_arrow.svg);
    }

    .filter.group.active {
        background-color: darken($color-white, 10%);
    }

    .filter:hover {
        color: darken($color-light, 45%);
        background-position: 100% 50%;
    }
}
