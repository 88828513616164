.customer-list {
    display: flex;
    flex-direction: column;
    color: var(--TABLE_FG);
    background-color: var(--MAIN_COLOR_BG_2);
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 400;
    font-size: rem(14px);
    border-radius: 5px;

    flex: 1;
    overflow: auto;

    .customer-item {
        display: flex;
        flex-direction: column;
        cursor: pointer;
        transition: $transition-mode;
    }

    .customer-item__item-header {
        display: flex;
        padding: 0em 0.5em;
        height: 3em;
        align-items: center;
        flex-direction: row;
    }

    .customer-item__item-header:not(.expanded):hover {
        background-color: darken($color-white, 10%);
        font-weight: 500;
    }

    .customer-item__item-header.expanded {
        font-weight: 500;
    }

    .customer-item__item-body {
        padding-left: 0.5em;
        flex-direction: row;
    }

    .item-header__title {
        flex: 0 0 50%;
        text-align: left;
    }

    .item-header__number {
        flex: 0 0 auto;
        text-align: left;
    }

    .item-header__count {
        font-size: rem(12px);
        flex: 0 1 2em;
    }

    .item-header__expand {
        flex: 1;
        text-align: right;
    }
}
