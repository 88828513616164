.header {
    height: 60px;
    padding: 5px;
    text-align: center;
    line-height: 50px;
    display: flex;
    justify-content: space-between;
    @include print {
        display: none;
    }
}

.theme-color-blue {
    .header {
        background-color: $color-blue;
        color: $color-blue-font;
    }
}

.theme-color-red {
    .header {
        background-color: $color-red;
        color: $color-red-font;
    }
}

.header--text {
    padding-left: 20px;
    font-size: rem(32px);
    font-weight: 500;
}

.container-header {
    border-bottom: 1px grey solid;
    @include print {
        display: none;
    }
}

*,
*:before,
*:after {
    box-sizing: border-box;
}
