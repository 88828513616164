@import "./../../../assets/styles/variables";
@import "./../../../assets/styles/mixins";

.timesafeoffer-phase--task {
    display: flex;
    text-align: left;
    gap: 0.3em;

    @include print {
        font-size: rem(13px);
        border: 1px solid black;
    }

    .task--title {
        flex: 0 0 20vw;
    }
    .task--tag,
    .task--target,
    .task--actual,
    .task--progress,
    .task--forecast,
    .task--forecast-percent,
    .task--open {
        flex: 0 0 150px;
        text-align: left;
    }

    .task--header {
        font-weight: 600;
    }

    .task--item {
        padding: 0.1em 0.1em;
        border-radius: 3px;
    }

    .task--braked{
        color: black;
        background-color:  black;
        border: 2px solid black;
    }
}
