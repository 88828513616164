.ds-openquote,
.ds-projects {
    .container__body {
        display: grid;
        width: 100%;
        height: 100%;
        grid-template-columns: 50% 50%;
        grid-template-rows: 30% 70%;
        overflow: hidden;
    }

    .openquoteurl__input {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 1;
        padding: 1.5em;
        border-bottom: 1px solid #9f9f9f;
    }

    .number {
        font-size: 3em;
        font-weight: 600;
    }

    .numOfCustomers,
    .db-customer-list {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 2;
        grid-row-end: 2;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }

    input {
        border: 1px solid #9f9f9f;
        border-radius: 4px;
        padding: 0.5em;
        width: 100%;
    }

    .db-customer-list {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        flex-direction: column;
        overflow: hidden;
        flex: 1;
    }

    .db-customer-list-item {
        min-height: 3em;
        width: 100%;
        padding-left: 1em;
        padding-right: 1em;
        padding-top: 0.8em;
        text-decoration: none;
        color: black;
        border-bottom: 1px solid #9f9f9f;
    }

    .nav-item-text {
        display: flex;
        justify-content: flex-start;
        > * {
            min-width: 4em;
            text-align: left;
        }
    }

    .text-customer-number {
        font-weight: 500;
    }

    .db-customer-list-item:hover {
        background-color: #dfdfdf;
    }

    .openquote__btn {
        background-color: #5f6a86;
        padding: 0em 2em;
        border-radius: 4px;
        height: 2em;
        line-height: 2em;
        display: block;
        justify-content: center;
        cursor: pointer;
        align-items: center;
        color: #dfdfdf;
        transition: "single-transition";
    }

    .openquote__btn:hover {
        transform: scale(1.02);
    }
}

.ds-customer {
    .container__header {
        background-color: #ff5252;
    }
}
.ds-projects {
    .container__header {
        background-color: #ffc107;
    }
}

.ds-calendar {
    .container__header {
        background-color: #20283e;
    }
}

ds-openquote {
    .container__header {
        background-color: #5f6a86;
    }
}
