// VARIABLES
//----------------------------------------------------------

$font-family: "Inter", sans-serif;

$size-site: 1744px;
$size-medium: 1448px;
$size-narrow: 1152px;
$size-viewport: 100vw;

$base-font-size: 18px;
$transition-mode: 100ms ease;

$break-xl: 1400px;
$break-l: 1200px;
$break-m: 1000px;
$break-s: 800px;
$break-xs: 600px;

$ds-percentage: 150px;

// COLORS
//----------------------------------------------------------

$color-red: #ea6a47;
$color-red-font: #f1f1f1;
$color-blue: #1c4e80;
$color-blue-font: #f1f1f1;

$color-black: #000000;
$color-white: #ffffff;
//https://aesalazar.com/blog/professional-color-combinations-for-dashboards-or-mobile-bi-applications
//https://coolors.co/0a0a0a-f0f4ef-618985
$color-dark: #2f2e2e;
$color-dark-font: #f0f4ef;
$color-dark-elements: #0a0a0a;

//https://coolors.co/0d43bf-e5e5e5-ffffff
$color-light: #e5e5e5;
$color-light-font: #000000;
$color-light-elements: #f1f1f1;

$color-green: #6cdb4a;

$color-light-blue: #b2c3cd;
$color-gray-middle: #cecece;
