input {
    background-color: $color-white;
    outline: none;
    border: none;
}

select {
    background-color: $color-white;
    border-radius: 0;
    border: none;
    outline: 0;
}

button {
    outline-color: $color-black;
    outline: 1;
    border: 0;
    cursor: pointer;
}

.input {
    display: flex;
    position: relative;
    padding: 0;
    margin: 0;

    input[type="text"],
    input[type="text"]:active {
        z-index: 0;
        flex: 1;
        background-color: transparent;
        height: 100%;
        border: 1px solid $color-light-blue;
        border-radius: 3px;
        padding: 0.7em 0.5em;
        outline: none;
    }

    input[type="text"] + label,
    .select + label {
        position: absolute;
        font-size: rem(16px);
        top: 0.4em;
        left: 0.2em;
        font-weight: 400;
        padding: 0em 0.2em;
        color: $color-light-blue;
        transition: $transition-mode;
    }

    .select {
        width: 100%;
        z-index: 3;
    }

    input[type="text"]:focus + label,
    input[type="text"]:not([value=""]) + label,
    select:focus + label,
    select:not([value=""]) + label {
        z-index: 2;
        position: absolute;
        padding: 0em 0.3em;
        color: $color-dark-elements;
        background-color: $color-white;
        font-size: rem(13px);
        top: -0.8em;
        left: 0.3em;
        font-weight: 500;
    }

    .filterList {
        background-color: $color-white;
        z-index: 3;
        border: $color-light-font 1px solid;
        border-radius: 0;
        margin: 0;
        padding: 0;
        position: absolute;
        width: 100%;
        top: 2.3em;
        left: 0em;
        list-style: none;
        display: none;
        > * {
            padding: 0.5em;
            display: flex;
            > * {
                text-align: left;
                flex: 1;
            }
        }

        > *:hover {
            background-color: $color-light-blue;
            color: $color-white;
        }
    }

    input:not([value=""])[type="text"]:focus ~ .filterList,
    .filterList.show {
        display: block;
    }
}
