@import "./../../../../assets/styles/variables";

.project-list {
    background-color: $color-white;
}

.project-item {
    display: flex;
    flex-direction: column;
    padding: 0em 0.5em;
    background-color: $color-white;
    border-left: 1px solid $color-dark;

    &:last-of-type {
        border-bottom: none;
    }

    .project-item__item-header {
        display: flex;
        height: 2em;
        flex-direction: row;
        align-items: center;
    }

    .project-item__item-header:hover {
        background-color: $color-light;
    }

    .item-header__title {
        flex: 0 0 40%;
        text-align: left;
        padding: 0em 0.5em;
    }

    .item-header__count {
        flex: 0 0 2em;
    }

    .project-item__item-body {
        display: flex;
        flex-direction: column;
        padding-left: 1em;
    }

    .item-header__expand {
        flex: 1;
        text-align: right;
    }
}
