@import "./../../assets/styles/variables";

.thanosV1--container {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: scroll;
    padding: 20px;

    input {
        border: 1px black solid;
        border-radius: 0;
    }

    select {
        border: 1px black solid;
        border-radius: 0;
    }

    .theme-dark {
        input {
            background-color: $color-dark;
            color: $color-dark-font;
        }
    }

    .theme-light {
        input {
            background-color: $color-light;
            color: $color-light-font;
        }
    }
}
