@import "./../../../assets/styles/variables";
@import "./../../../assets/styles/mixins";

.quote-edit-document__directory {
    max-width: 15em;
    width: 15em;
    overflow: hidden;
    background-color: $color-gray-middle;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: wrap;
    text-align: left;
    font-size: rem(14px);
    font-weight: 300;
    overflow: visible;

    .quote-edit-document__directory-item {
        cursor: pointer;
        font-weight: 500;
        border-bottom: $color-light-elements 1px solid;
        display: flex;
        justify-content: space-between;
        align-content: center;
    }

    .directory-item--title {
        padding: 0.7em 0.5em;
        flex: 1;
    }
    .directory-item--title:hover {
        color: lighten($color-black, 40%);
    }

    .directory-item--menu {
        vertical-align: middle;
        padding: 0.7em 0.1em;
    }

    .context-menu {
        list-style: none;
        padding: 0;
        margin: 0;
    }
    .context-menu-item {
        list-style: none;
        padding: 0.5em 1em;
        border-bottom: 1px solid;

        &:last-child {
            border-bottom: 0px solid;
        }
    }

    .context-menu-item:hover {
        list-style: none;
        background-color: darken($color-white, 5%);
        padding: 0.5em 1em;
    }
}
