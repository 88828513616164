.settings.list {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: 1em;
    gap: 1em;

    .list-item {
        display: flex;
    }

    .item-title {
        flex: 1;
        color: black;
    }

    .item-content {
        flex: 1;
        height: 6rem;
        resize: none;
    }
}
