@import "./../../../assets/styles/variables";

.container__quote-document {
    display: flex;
    margin: 1em auto;
    overflow: hidden;
    flex-direction: row-reverse;
    max-width: $size-medium;
    width: 100%;

    /* width */
    ::-webkit-scrollbar {
        width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: $color-red;
        border-radius: 5px;
        border: none;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}

.sum-quote {
    color: black;
    text-align: right;
    flex: 0 0 280px;
    margin: 1em 0em;
    font-weight: 500;
    font-size: 16px;
    text-decoration: underline;
    text-underline-offset: 0.1em;
}

.sum-quote-time {
    color: black;
    text-align: right;
    flex: 1;
    margin: 1em 0em;
    font-weight: 500;
    font-size: 16px;
    text-decoration: underline;
    text-underline-offset: 0.1em;
}

.add-phase__button {
    background-color: $color-red;
    color: $color-white;
    margin-top: 0.5em;
    padding: 0.5em;
    width: 20rem;
}

.quote-document {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: scroll;
    overflow-x: hidden;
    background-color: $color-white;
    padding: 2em;
    scroll-behavior: smooth;
}

.offerlist__item {
    margin: 0vw 0vw;
}

.offerlist--offerstate-container {
    display: flex;
    margin: 0.5vw 2vw;
    > * {
        flex: 1;
    }
}

.offerlist--phasestones {
    display: flex;
    flex-wrap: wrap;
}

.offerlist--list {
    display: flex;
    flex-direction: column;
}
.offerlist--phase-container {
    flex: 1;
    border-radius: 2px;
}

.theme-color-red {
    .offerlist--offerstate-container .active {
        border-bottom: $color-red 1px solid;
        color: $color-red;
        font-weight: 500;
    }
}
.theme-color-blue {
    .offerlist--offerstate-container .active {
        border-bottom: $color-blue 1px solid;
        color: $color-blue;
        font-weight: 500;
    }
}

//
.offerlist--phase-switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
    margin-bottom: 5px;
}

.offerlist--phase-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}
.phase-switch-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}
.phase-switch-slider:before {
    position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.theme-color-red {
    input:checked + .phase-switch-slider {
        background-color: $color-red;
    }
    input:focus + .phase-switch-slider {
        box-shadow: 0 0 1px $color-red;
    }
}
.theme-color-blue {
    input:checked + .phase-switch-slider {
        background-color: $color-blue;
    }
    input:focus + .phase-switch-slider {
        box-shadow: 0 0 1px $color-blue;
    }
}

input:checked + .phase-switch-slider:before {
    -webkit-transform: translateX(10px);
    -ms-transform: translateX(20x);
    transform: translateX(20px);
}
.phase-switch-text {
    position: absolute;
    left: 50px;
    width: 10em;
    font-weight: 400;
    font-size: rem(16px);
}

.quote-footer {
    display: flex;
}
