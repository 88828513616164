@import "./assets/styles/main.scss";

#root {
    height: 100%;
    width: 100%;
}

.theme-light {
    background-color: $color-light-elements;
    color: $color-light-font;
}

.theme-dark {
    background-color: $color-dark-elements;
    color: $color-dark-font;
}

.App {
    text-align: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.App-logo {
    height: 35vmin;
    pointer-events: none;
    overflow: hidden;
}

.main {
    display: flex;
    flex-direction: row;
    flex: 1;
    height: calc(100% - 60px);
}

.container {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    height: 100%;
    text-align: center;
}

.container-content {
    display: flex;
    flex-direction: column;
    margin: 10px;
}

.imgGroot {
    height: 80px;
}

.logged-out-screen {
    flex: 1;
    background-color: $color-blue;
    font-size: 4rem;
    color: $color-white;
    font-weight: 400;
    text-shadow: 0 0 7px #fff, 0 0 10px #fff, 0 0 42px #fa0, 0 0 92px #fff, 0 0 102px #fa0;

    p:last-of-type {
        text-shadow: 0 0 7px #fff, 0 0 82px #fff;
        font-size: 2rem;
    }
}

/* Arrow */

.login-text {
    font-size: 16px;
    font-family: "Courier New", Courier, monospace;
    position: absolute;
    right: 5px;
    text-shadow: none;
    top: 55px;
}

@media (max-width: 800px) {
    .main {
        flex-direction: column;
    }
}
