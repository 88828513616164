.quote-item {
    height: fit-content;
    width: 100%;
    background-color: $color-white;
    border-bottom: 1px solid $color-light;
    font-size: rem(14px);
    margin-bottom: 0em;

    &:last-of-type {
        border: none;
    }

    a {
        text-decoration: none;
        color: $color-dark-elements;
    }

    header {
        padding-top: 0.5em;
        font-weight: 400;
        border-bottom: 1px solid black;
    }

    header,
    .row {
        display: flex; /* aligns all child elements (flex items) in a row */
        justify-content: center;
        align-items: center;
        height: 2em;
    }

    .row {
        padding: 1.5em 0;
    }

    .row:hover {
        background-color: darken($color-white, 10%);
    }

    .col {
        flex: 1; /* distributes space on the line equally among items */
        text-align: left;
        padding: 0em 0.5em;
        text-decoration: none;
    }

    .state {
        background-color: $color-green;
        color: $color-white;
        padding: 0.2em;
        width: fit-content;
        border-radius: 5px;
    }
}

.quote-list {
    background-color: $color-white;

    .quote-item:not(:first-child) {
        header {
            display: none;
        }
    }
}
