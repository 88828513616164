.container__filter {
    overflow: auto;

    .filter__titlebar {
        flex: 0 0;
        background-color: $color-white;
        padding: 0.5em 1.5em;
        align-content: flex-start;
        text-align: left;
        font-size: rem(16px);
        font-weight: 700;
        margin-bottom: 1em;
        text-transform: uppercase;
    }

    .filter__filter-main {
        overflow: auto;
        display: flex;
        flex: 1;
        background-color: $color-white;

        @include m {
            flex-direction: column;
        }
    }
}
