.form {
    display: flex;
    flex-direction: column;
    font-size: rem(16px);

    .form__state {
        display: flex;
        flex-direction: column;
    }

    .form-header,
    .form-footer {
        padding: 1em 0em;
        display: flex;
        justify-content: center;
        gap: 1em;
    }

    .form-footer {
        justify-content: space-between;
    }

    .dot {
        width: 1em;
        height: 1em;
        border-radius: 50%;
        background-color: $color-light-blue;
        transition: 0.3s ease-in-out;
    }

    .dot.active {
        background-color: $color-blue;
    }

    .row {
        padding: 0.5em 0em;
        flex: 0 0 100%;
        display: flex;
        flex-direction: row;
        gap: 1em;

        > * {
            flex: 1;
        }
    }

    .title {
        text-align: left;
        font-weight: 500;
    }

    .button {
        background-color: $color-blue;
        color: $color-white;
        padding: 0.5em 2em;
        cursor: pointer;
    }

    .button__next {
        margin-left: auto;
    }

    .button__add {
        background-color: $color-green;
    }
}
