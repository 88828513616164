@import "./../../assets/styles/variables";

.nav {
    width: 50px;
    min-width: 50px;
    background-color: $color-blue;
    color: $color-blue-font;
    display: flex;
    flex-direction: column;
}

.nav.max {
    min-width: 200px;
}

.nav-list {
    padding: 0;
    margin: 0;
}

.nav-title {
    flex: 1;
    font-size: 20px;
    line-height: 45px;
    margin-left: 5px;
}

.nav-item {
    display: flex;
    justify-content: left;
    font-style: normal;
    font-size: rem(16px);
    background-color: var(--COLOR_PRIMARY);
    color: var(--COLOR_PRIMARY_FONT);
    margin: auto;
    text-transform: uppercase;
    text-decoration: none;
    margin-bottom: 10px;
    height: 1.5em;
    text-indent: 3em;
    width: 100%;
    position: relative;

    &.menu {
        background-image: url(./../../assets/images/icon_menu.svg);
    }

    &.ordering {
        background-image: url(./../../assets/images/icon_ordering.svg);
    }

    &.timesafe {
        background-image: url(./../../assets/images/icon_ts.png);
    }

    &.home {
        background-image: url(./../../assets/images/icon_home.svg);
    }

    &.settings {
        background-image: url(./../../assets/images/icon_templates.svg);
    }

    &.quotes {
        background-image: url(./../../assets/images/icon_quotes.svg);
    }

    &.cancel {
        background-image: url(./../../assets/images/icon_cancel.svg);
    }

    &.projects {
        background-image: url(./../../assets/images/icon_project.svg);
    }

    &.customers {
        background-image: url(./../../assets/images/icon_customer.svg);
    }

    &.templates {
        background-image: url(./../../assets/images/icon_templates.svg);
    }

    &.controlling {
        background-image: url(./../../assets/images/icon_controlling.svg);
    }

    &.old {
        background-image: url(./../../assets/images/icon_old.svg);
    }
}

.nav .icon {
    background-repeat: no-repeat;
    background-position: 50% 50%;
    vertical-align: middle;
}

.nav.max .icon {
    background-repeat: no-repeat;
    background-position: 10% 50%;
    vertical-align: middle;
}

.nav-item-text {
    position: unset;
    margin: auto auto auto 5px;
}

.min .nav-item-text {
    display: none;
    position: absolute;
}

.min .nav-item:hover {
    .nav-item-text {
        background-color: lighten($color-blue, 10%);
        z-index: 1;
        margin: 0;
        color: white !important;
        top: 0;
        height: 100%;
        display: inline-block;
        left: 100%;
        padding: 0.15em 0.5em;
        text-indent: 0;
        box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
    }
}

.nav-item:first {
    padding-top: 0px;
}

.nav-item:hover {
    background-color: lighten($color-blue, 10%);
    color: var(--NAV_ITEM_COLOR_FG_HOVER);
}

nav input {
    display: none;
}
.head {
    display: none;
}

@media (max-width: 800px) {
    .nav {
        display: flex;
        width: auto;
        flex-direction: column;
        border: none;
        border-bottom: 3px solid grey;
    }

    .head {
        display: flex;
        flex-direction: row;
    }

    .nav-list {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height: 0px;
    }

    .nav :checked + * + .nav-list {
        height: auto;
    }

    .nav :checked + * + * + .nav-item {
        height: 0px;
    }

    .nav ul {
        overflow: hidden;
        margin: 0;
    }

    .nav :checked ~ ul {
        margin: 16px;
    }

    .nav :checked ~ ul {
        height: 2em;
        line-height: 2em;
        padding: 0px;
        text-align: center;
    }

    .nav :checked ~ .nav-list {
        overflow: visible;
    }

    nav label:before {
        content: "≡";
        cursor: pointer;
        margin-right: 10px;
    }
    nav :checked + label:before {
        content: "x";
    }
    nav label {
        font-size: 40px;
        align-self: end;
    }
}

.theme-color-blue {
    .nav {
        background-color: $color-blue;
        color: $color-blue-font;
    }
}

.theme-color-red {
    .nav {
        background-color: $color-red;
        color: $color-red-font;
    }

    .nav-item:hover {
        background-color: lighten($color-red, 10%);
        color: var(--NAV_ITEM_COLOR_FG_HOVER);
    }

    .min .nav-item:hover {
        .nav-item-text {
            background-color: lighten($color-red, 10%);
        }
    }
}
