.timesafeoffer-container--header {
    display: flex;
    height: fit-content;
    flex: 0;
}

.timesafeoffer-container--content {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 5px;
}
