@use 'sass:math';

@mixin xl {
    @media (max-width: #{$break-xl}) {
        @content;
    }
}

@mixin l {
    @media (max-width: #{$break-l}) {
        @content;
    }
}

@mixin m {
    @media (max-width: #{$break-m}) {
        @content;
    }
}

@mixin s {
    @media (max-width: #{$break-s}) {
        @content;
    }
}

@mixin xs {
    @media (max-width: #{$break-xs}) {
        @content;
    }
}

@mixin print {
    @media print {
        @content;
    }
}

@function rem($pixels) {
    @return math.div($pixels, $base-font-size) * 1rem;
}
