@import "./../../../assets/styles/variables";
@import "./../../../assets/styles/mixins";

.phase--task-list-header {
    padding: 5px;
    display: flex;
    font-size: rem(16px);
    font-weight: 500;
    width: 100%;
    justify-content: space-between;
    border-bottom: 2px black solid;
    margin-bottom: 1em;
    gap: 0.5em;

    .task-list-header__position {
        flex: 1;
        text-align: left;
    }
    .task-list-header__unit {
        flex: 0 0 5em;
    }

    .task-list-header__workgroup {
        flex: 0 0 15em;
        text-align: center;
    }
    .task-list-header__rate {
        flex: 0 0 5em;
    }
    .task-list-header__duration {
        flex: 0 0 7em;
    }
}
