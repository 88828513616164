@import "./../../../assets/styles/variables";
@import "./../../../assets/styles/mixins";

.timesafeoffer--container {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 90vw;
    margin: 0 auto;
    overflow: scroll;
    background-color: $color-white;

    @include print {
        background-color: $color-white;
    }

    .timesafeoffer-phase--task:nth-child(2n + 1) {
        background-color: darken($color: #ffffff, $amount: 1);
    }

    .timesafeoffer--phaseTitle{
        display: flex;
        flex-direction: row;
        font-weight: bold;
        font-size: 22px;
        margin-top: 20px;
    }
}
