@import "./../../../assets/styles/variables";

.quote-edit-document__edit-bar {
    max-width: 3em;
    width: 2.5em;
    padding: 0.2em;
    overflow: hidden;
    background-color: $color-light;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    margin-left: 1em;
    justify-content: flex-start;
    flex-wrap: wrap;

    .edit-bar__icon-pdf {
        background-color: $color-gray-middle;
        border-radius: 5px;
        padding: 0.15em;
        width: 100%;
        height: auto;

        path {
            color: black;
        }
    }
    .edit-bar__icon-pdf:hover {
        path {
            color: $color-red;
        }
    }

    .edit-bar__icon-pdf.active {
        background-color: $color-red;
        border-radius: 5px;
        padding: 0.15em;
    }
    .edit-bar__icon-pdf.active:hover {
        path {
            color: white;
        }
    }
}
