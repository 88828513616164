h1{
    font-weight: 700;
}

h2{
    font-weight: 500;
}

h3{
    font-weight: 400;
}